.tooltip {
  &:global(.ant-tooltip) {
    max-width: 208px;
    padding: 0;
  }
}

.modal {
  background-color: #fff;

  & > div + div {
    height: 100%;
  }

  &:global(.ant-modal-content) {
    height: 100%;
  }
}
