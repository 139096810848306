.dropdown {
  max-width: 192px;

  :global(.ant-dropdown-menu) {
    width: 100%;
  }

  :global(.ant-dropdown-menu-item) {
    padding: 0;
  }
}

.secondary {
  max-width: 168px;
  width: 100%;

  :global(.ant-dropdown-menu) {
    width: 100% !important;
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  :global(.ant-dropdown-menu-item) {
    padding: 0;
  }
}

:global(.ant-picker-dropdown) {
  z-index: 10000;
}

:global(.ant-dropdown) {
  z-index: 10000;
}
