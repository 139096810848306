.primary {
  &:global(.ant-tooltip) {
    max-width: 320px;
  }

  :global(.ant-tooltip-placement-right, .ant-tooltip-placement-rightTop, .ant-tooltip-placement-rightBottom) {
    padding-left: 0px;
  }

  :global(.ant-tooltip-inner) {
    padding: 0;
    border-radius: 4px;
    box-shadow: none;
    background-color: transparent;
    position: relative;

    &::after {
      @media screen and (max-width: 743px) {
        left: 302px;
        top: 12px;
        transform: rotate(180deg);
      }
      content: '';
      position: absolute;
      left: -12px;
      top: 12px;
      width: 0;
      height: 0;
      border-width: 12px 12px 12px 0;
      border-color: transparent #FFFFFF transparent transparent;
      border-style: solid;
    }
  }

  :global(.ant-tooltip-arrow-content) {
    display: none;
  }
}
