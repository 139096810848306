:global(.react-datepicker-popper) {
  z-index: 2099;
}

:global(.react-datepicker-popper .react-datepicker__navigation-icon::before) {
  top: 12px;
}

:global(.react-datepicker-popper .react-datepicker__day--outside-month:not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled))  {
  color: #787878;
}
