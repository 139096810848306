.primary {
  :global(.ant-tooltip-inner) {
    border-radius: 2px;
    background-color: #505563;
    padding: 0 10px 2px;
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    min-height: initial;
    text-align: center;
  }

  :global(.ant-tooltip-arrow) {
    width: 14px;
    height: 10px;
  }

  :global(.ant-tooltip-arrow-content::before) {
    background: #505563;
  }

  &:global(.ant-tooltip-placement-top) {
    padding-bottom: 4px;
  }

  &:global(.ant-tooltip-placement-top .ant-tooltip-arrow-content) {
    transform: translateY(-8px) rotate(45deg);
  }
}

.secondary {
  &:global(.ant-tooltip) {
    max-width: 210px;
  }

  :global(.ant-tooltip-placement-right, .ant-tooltip-placement-rightTop, .ant-tooltip-placement-rightBottom) {
    padding-left: 0px;
  }

  :global(.ant-tooltip-inner) {
    background: #fff;
    color: rgba(27, 42, 61, 0.8);
    font-family: Inter;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0px 6px 6px 6px;
    border-left: 3px solid #418db8;
  }

  :global(.ant-tooltip-arrow-content) {
    display: none;
  }
}

.tertiary {
  &:global(.ant-tooltip) {
    max-width: 318px;
  }

  :global(.ant-tooltip-placement-right, .ant-tooltip-placement-rightTop, .ant-tooltip-placement-rightBottom) {
    padding-left: 0px;
  }

  :global(.ant-tooltip-inner) {
    padding: 0;
    border-radius: 4px;
    box-shadow: none;
    background-color: transparent;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: -8px;
      top: 4px;
      width: 0;
      height: 0;
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent #FFFFFF;
      border-style: solid;
    }
  }

  &:global(.ant-tooltip-placement-rightBottom .ant-tooltip-inner) {
    &::after {
      top: initial;
      right: initial;
      bottom: 8px;
      left: -8px;
      transform: rotate(180deg);
    }
  }

  &:global(.ant-tooltip-placement-topLeft .ant-tooltip-inner) {
    &::after {
      transform: rotate(90deg);
      top: initial;
      bottom: -12px;
      left: 8px;
      right: initial;
    }
  }

  :global(.ant-tooltip-arrow-content) {
    display: none;
  }
}
