.mt-2 {
  margin-top: 20px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-5 {
  margin-top: 50px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-5 {
  margin-bottom: 50px;
}

.mr-2 {
  margin-right: 20px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 30px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-1 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 30px;
}

.p-1 {
  padding: 10px;
}

.pt-1 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.ph-2 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph-3 {
  padding-left: 30px;
  padding-right: 30px;
}

.text_primary {
  color: #1F85CF !important;
}

.bg_primary01 {
  background-color: #04192E !important;
}

.text_light_navy_light {
  color: #B9C9DA !important;
}

.text_white {
  color: #fff !important;
}

.text_black {
  color: #000000 !important;
}

.text_blue_dark {
  color: #112538 !important;
}

.text_light_navy {
  color: #5D83AB !important;
}

.text_light_grey {
  color: #858E99 !important;
}

.width70 {
  width: 70%;
}

.text_light_navy_1 {
  color: #798B99 !important;
}

.text_grey {
  color: #878787 !important;
}

.text_blue_normal {
  color: #1689CA !important;
}

.downIcon {
  margin-left: 10px;
  margin-right: -15px;
}

.text_red {
  color: #F00000 !important;
}

.text_tag {
  color: #FEAB3D !important;
}

.text_bold {
  font-weight: bold !important;
}

.text_500 {
  font-weight: 500 !important;
}

.text_400 {
  font-weight: 400 !important;
}

.font-8 {
  font-size: 9px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-28 {
  font-size: 28px !important;
}

.radius-1 {
  border-radius: 5px;
}

.radius-2 {
  border-radius: 10px !important;
}

.c-pointer {
  cursor: pointer !important;
}

.text-left {
  text-align: left !important;
}

.fullWidth {
  width: 100% !important;
}

.width110 {
  margin-left: -5%;
  width: 110% !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.row {
  display: flex;
  align-items: center;
}

.b-1 {
  border-radius: 5px;
}

.b-2 {
  border-radius: 10px;
}

.bg_light_blue {
  background-color: #E2F1FF;
}

.labelOption {
  width: 100px;
  background-color: #1271A6 !important;
  color: #fff !important;
}

.dropdown-border-bottom {
  border-bottom: 1px solid #fff
}

.flex-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.goalDropdown {
  margin-bottom: -5px !important;
  align-items: center;
  display: flex;
  cursor: pointer;
  /* text-transform: uppercase; */
  color: #fff
}

.ownerTag>.rc-virtual-list>div>div>div>div>div {
  color: #0787F8;
  flex: none;
  width: max-content !important;
  background-color: #EAF5FD;
  border-radius: 20px;
  padding: 2px 10px;
}

/* .custom-input {
  font-family: "Inter";
  width: 48px;
  font-size: 14px;
  line-height: 20px;
  color: #112538;
  margin-right: 10px;
} */

.upercase {
  text-transform: uppercase !important;
}

.greyBox {
  background: #B9C9DA;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  border-radius: 25px;
  width: 25px;
  margin-right: 10px;
  height: 25px;
}

.removeOption {
  width: 150px;
  background-color: #04192E;
}

.goalOption {
  width: 120px;
  background-color: #1271A6;
  margin: 0%;
}

.goalPopupOption>ul {
  background-color: #1271A6 !important;
}

.goalWeightOption>.ant-dropdown-menu-submenu-title {
  color: #fff !important
}

.goalWeightOption>div>span>span {
  color: #fff !important
}

.goalWeightOption>div:hover {
  background-color: rgba(81, 108, 133, 0.1) !important;
}

.goalPopupOption>ul>li:hover {
  background-color: rgba(81, 108, 133, 0.1) !important;
}

.goalOption>li:hover {
  background-color: rgba(81, 108, 133, 0.1);
}

.removeOption>li:hover {
  background-color: rgba(81, 108, 133, 0.1);
}

.peoplePop {
  width: 400px !important;
}

.addedButton {
  border-color: #1F85CF !important;
  color: #1F85CF;
  font-size: 12px;
  height: 25px;
  font-weight: bold;
  padding-top: 3px;
}

.peopleHeight {
  max-height: 200px;
  overflow: auto;
}

.addButton {
  background-color: #1F85CF !important;
  color: #fff;
  font-size: 12px;
  height: 25px;
  font-weight: bold;
  padding-top: 3px;
}

.addRemoveButton {
  background-color: #1F85CF !important;
  color: #fff;
  font-size: 12px;
  height: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-top: 0px;
}

.RemoveButton {
  background-color: #ff0000 !important;
  border-color: #ff0000;
  color: #fff;
  font-size: 12px;
  height: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-top: 0px;
}

.RemoveButton:hover {
  border-color: #ff0000;

}

.removeText {
  margin-left: 5px;
  margin-top: 2px;
}

.table-cell-value {
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  color: #112538;
}

.greyBoxCategory {
  background: #B9C9DA;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto;
  /* text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 100;
  font-size: 12px;
  border-radius: 25px;
  width: 25px;
  margin-left: 30%;
  height: 25px; */
}

.BreadcrumbHome {
  background-color: #F3F9FF;
  margin-bottom: 0px;
  border-radius: 20px;
  width: fit-content;
  padding: 2px 10px !important;
  color: #1F85CF;
  font-weight: normal;
}

.BreadcrumbPlanDetails {
  background-color: #C2DEFB;
  margin-bottom: 0px;
  border-radius: 20px;
  width: fit-content;
  padding: 2px 10px !important;
  color: #06203A;
  font-weight: normal;
}

.progressBox {
  /* background: #F8F8F8; */
  border-radius: 5px;
  margin-top: 5px;
  /* padding: 10px; */
}

.progressBoxDetails {
  background: #F8F8F8;
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px;
}

.valueBox {
  background: #FAFAFA;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 4px;
  padding: 2px 5px;
}

.createPlanBtn {
  background-color: #4B8121;
  border-color: #4B8121;
  text-transform: uppercase;
  height: 40px;
  border-radius: 5px !important;
}

.cancelPlanBtn {
  height: 40px;
}

.fullSelect {
  width: 100%;
  font-size: 14px !important;
}

.PlanSelectActive {
  border-radius: 0px !important;
  border: 1px solid #E3E5E9 !important;
  background-color: #fff !important;
  color: #1B2A3D !important;
}

.PlanSelectActive>.ant-select-arrow {
  color: #8E97A3 !important;
}

.PlanSelectActive>div {
  border-color: #d9d9d9 !important;
  border-radius: 3px !important;
}


.PlanSelectActive>div>.ant-select-selection-placeholder {
  color: #8E97A3 !important;
  font-weight: 400 !important;
}

.PlanSelect {
  border-radius: 0px !important;
  border: 1px solid #E3E5E9 !important;
  background-color: #fff !important;
  color: #1B2A3D !important;
}

.PlanSelect>.ant-select-arrow {
  color: #8E97A3 !important;
}

.PlanSelect>div {
  border-color: #d9d9d9 !important;
  border-radius: 3px !important;
}


.PlanSelect>div>.ant-select-selection-placeholder {
  color: #8E97A3 !important;
  font-weight: 400 !important;
}

.PlanSelect>div>.ant-select-selection-item {
  color: #0787F8;
  flex: none;
  height: 30px;
  line-height: 25px !important;
  width: max-content !important;
  border-radius: 20px;
  margin-top: 5px;
  padding: 2px 10px !important;
}

.PlanSelectActive>div>.ant-select-selection-item {
  color: #0787F8;
  flex: none;
  height: 30px;
  line-height: 25px !important;
  width: max-content !important;
  background-color: #EAF5FD;
  border-radius: 20px;
  margin-top: 5px;
  padding: 2px 10px !important;
}

.addNote {
  color: #418DB8;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: -10px;
  font-weight: 700;
  margin-bottom: 10px;
}

.actionName {
  width: 87%;
}

.catName {
  width: 65%;
  min-height: 40px;
  margin-left: 20px;
}

.c-word-break {
  word-break: break-word;
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
}

.plusIcon {
  margin-right: 5px;
}

.tags {
  height: 20px !important;
  font-size: 12px !important;
}

.areaListBox {
  width: 250px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #F5F6FA;
  color: #1B2A3D;
  padding: 0% 15px;
  margin-bottom: 10px;
}

.areaListBoxFront {
  width: 250px;
  height: 80px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  color: #1B2A3D;
  padding: 0% 15px;
  margin-bottom: 10px;
}

.areaTextBox {
  width: 40px;
  height: 20px;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  margin-left: 20px;
  background-color: #F5F6FA;
  color: #1B2A3D;
  border-radius: 20px;
}

.checkboxDivFilter {
  width: 250px;
}

.transparentBG {
  background-color: transparent !important;
  border: none !important;
}

.checkboxDivFilterSub {
  width: 240px;
  height: 30px;
}

.checkboxCollapseFilter {
  width: 250px;
  height: 30px;
}

.fillButton {
  background-color: #2163A6;
  border-color: #2163A6
}

.whiteButton {
  color: #2163A6;
  background-color: #fff;
  border-color: #fff
}

.font_light {
  font-weight: 300 !important;
}

/* Login CSS */

.authLayout {
  width: 100%;
  height: 100%;
}

.leftPanel {
  background-color: #1F85CF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  width: 100%;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 100%;
}

.forgotpass {
  color: #00FFF0;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.otheraccount {
  margin-top: 30px;
  margin-bottom: -35px;
  text-align: center !important;
  padding: 0px 10px;
  width: fit-content;
  z-index: 1;
  background-color: #fff !important;
}

.loginButton {
  background-color: #082847;
  border-color: #082847;
  height: 40px;
  width: 100%;
  text-transform: uppercase;
  border-radius: 5px;
}

.sigupButton {
  width: 100%;
  background-color: #47B0EB;
  text-transform: uppercase;
  border-color: #47B0EB;
  height: 40px;
  border-radius: 5px;
}

.appInput {
  border-radius: 5px;
  /* font-size: 14px !important; */
}

.isNewUserModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skipSetupButton {
  height: 60px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #EBAC42;
  border: none;
  color: #fff;
  border-radius: 10px;
}

.setupButton {
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #112538;
  border: none;
  color: #fff;
  border-radius: 10px;
}

.inactiveTab {
  /* border: 1px solid #5D83AB; */
  color: #fff;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.isActiveTab {
  /* border: 1px solid #5D83AB; */
  /* background-color: #5D83AB; */
  color: #fff;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.createProjectButton {
  background-color: #FF9900;
  border: 1px solid #FF9900;
  border-radius: 4px;
  height: 40px;
  width: 150px;
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 20px;
}

.dropdownLabel {
  color: #5D83AB;
  text-transform: uppercase;
  margin-right: 10px;
}

.ownerEmailDiv {
  border: 1px solid #B9C9DA;
  border-radius: 3px;
  max-width: fit-content;
  padding: 5px 10px;
}

.underline {
  text-decoration-line: underline;
}

.ownerModalEmail {
  width: 100%;
  padding: 10px;
  min-height: 80px;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.inviteButton {
  width: 100%;

}

.halfWidth {
  width: 48% !important;
}

.uppercase {
  text-transform: uppercase;
}

.redButton {
  background-color: #F00000;
  border-color: #F00000;
}

.outlinePrimaryButton {
  width: 100%;
  background-color: white;
  border: 1px solid #E3E5E9;
  color: #0787F8;
  height: 50px;
  border-radius: 5px;
}

.buttonHeight {
  height: 50px;
}

.VLine {
  border-right: 1px solid #fff !important;
}

.detailsCard {
  border-radius: 0%;
  min-height: 90px;
  border: 1px solid #F3FBFF;
}

.detailsCard>div {
  padding: 15px !important;
  border-radius: 0%;
}

.orangeRow {
  border-left: 2px solid orange !important;
}

.hide {
  display: none;
}

.deleteDiv:hover .hide {
  display: block;
}

.addAction {
  position: absolute;
  right: 15px;
  color: #FFA61F;
  font-size: 12px;
  font-weight: 600;
}

.positionRight {
  position: absolute;
  right: 20px;
}

.positionEnd {
  position: absolute;
  right: 0px;
}

.positionCaret {
  position: absolute;
  right: 5px;
}

.deleteIcon {
  width: 15px;
  height: 15px;
}

.deleteCategory {
  /* background-color: #fff; */
  /* width: 30px; */
  height: 22px;
  padding-left: 8px;
  padding-top: 2px;
  border-radius: 5px;
  -webkit-box-shadow: 4px 10px 42px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 10px 42px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 10px 42px -12px rgba(0, 0, 0, 0.75);
}

.topBar {
  background-color: #1271A6;
  padding: 15px;
  border-radius: 5px;
}

.sortRadio>.ant-radio-checked .ant-radio-inner {
  border-color: #FEAB3D !important;
}

.sortRadio>.ant-radio-checked .ant-radio-inner:after {
  background-color: #FEAB3D;
}

.sortRadio>.ant-radio:hover .ant-radio-inner {
  border-color: #FEAB3D;
}

.sortRadio>.ant-radio .ant-radio-inner {
  background-color: transparent;
  border-width: 2px;
}

.AscendingBox {
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 5px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
}

.color-white {
  filter: brightness(0) invert(1);
}

.selectTableRow {
  margin-bottom: -35px;
}

.divCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}


.sortByDiv {
  background-color: #1271A6 !important;
  margin: -4px 0px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.sortDivider {
  background-color: #fff;
  width: 120%;
  margin-left: -20px;
}

@keyframes spinner-animate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
