.dropdown {
  :global(.ant-select-selection-item) {
    & > div {
      gap: 8px;
      padding: 5px 0;

      & > img {
        width: 20px;
        height: 20px;
      }

      & > div {
        & > span:last-of-type {
          display: none;
        }
      }
    }

    &::after {
      content: none !important;
    }
  }
}
