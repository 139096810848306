.dropdown {
  width: 100%;
  max-width: initial !important;

  :global(.ant-dropdown-menu) {
    overflow-y: auto;
    width: 284px;
    height: auto;
    max-height: 260px;
    top: 12px;
    left: -240px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .empty {
    border-color: #feab3d !important;
  }

  :global(.rc-virtual-list) {
    padding-right: 6px;
  }

  :global(.rc-virtual-list-holder) {
    &::-webkit-scrollbar:vertical {
      width: 4px !important;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent !important;
    }
  
    &::-webkit-scrollbar-thumb:vertical {
      background-color: #CED0D4;
      border-radius: 100px;
    }
  }
}
