.dropdown {
  max-width: 145px;

  :global(.ant-dropdown-menu) {
    width: 145px !important;
    border-radius: 0;
    padding: 4px;
  }

  :global(.ant-dropdown-menu-item) {
    padding: 0;
  }

  :global(.ant-dropdown-menu-item),
  :global(.ant-dropdown-menu-submenu-title),
  :global(.ant-dropdown-menu-item-only-child) {
    font-family: Inter;
    font-size: 12px;
    line-height: 20px;
    padding: 10px;
    color: #1B2A3D;
    display: flex;
    align-items: center;
  }

  :global(.ant-dropdown-menu-item-group-title[title="Custom Metrics"]) {
    color: #112538;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  :global(.ant-dropdown-menu-item:hover),
  :global(.ant-dropdown-menu-submenu-title:hover),
  :global(.ant-dropdown-menu-item-only-child:hover) {
    background: var(--primary-50, #ECF3F6);
    color: #1271A6;
    font-weight: 600;
  }

  :global(.ant-dropdown-menu-item:last-of-type) {
    color: #1271A6;
  }

  :global(.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title) {
    font-weight: 600;
    color: #1271A6;
    background-color: var(--Primary-50, #ECF3F6);
  }

  :global(.ant-dropdown-menu-submenu-expand-icon) {
    top: 0;
    background-image: url('../../../../assets/images/journeys/chevron-right.svg');
    width: 20px;
    height: 20px;
    position: static;

    span {
      display: none;
    }
  }

  :global(.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-expand-icon) {
    background-image: url('../../../../assets/images/journeys/chevron-right-blue.svg');
  }
}

:global(.ant-dropdown-menu-submenu-popup) {
  width: auto !important;
  min-width: 135px/*  !important */;
  padding: 0;
  margin-left: -12px;
  background-color: #fff;
}

:global(.ant-dropdown-menu-submenu-popup .ant-dropdown-menu) {
  width: auto !important;
  min-width: 135px/*  !important */;
  box-shadow: none;
  padding: 4px;
  margin-right: 0;
  margin-left: 0px;
  top: 0;
}

:global(.ant-dropdown-menu-item-selected) {
  font-weight: 600;
  background-color: var(--Primary-50, #ECF3F6);
  background-image: url('../../../../assets/images/journeys/unit-tick.svg');
  background-position: calc(100% - 10px) 6px;
  background-repeat: no-repeat;
  background-size: 20px;
  color: #1271A6;
}

:global(.ant-picker-dropdown) {
  z-index: 99999;
}

:global(.ant-dropdown-menu-submenu-popup) {
  z-index: 10000;
}

:global(.ant-dropdown) {
  z-index: 10000;
}
