@import "react-big-calendar/lib/sass/styles";

.my-calendar-class {
  .rbc-header {
    border: none;

    .rbc-header + .rbc-header {
      border-left: none !important;
    }

    .rbc-row-bg {
      display: none !important;
    }
  }
  .rbc-date-cell {
    text-align: center;
  }
  .rbc-off-range-bg {
    background-color: transparent;
  }

  .rbc-today {
    background-color: transparent;
    .rbc-header-date {
      color: #448fff !important;
    }
    .rbc-header-day {
      color: #448fff !important;
    }
  }

  .rbc-current {
    .rbc-button-link {
      background-color: #e4bf44;
      padding: 8px;
      border-radius: 50%;
    }
  }

  .rbc-row-segment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: #f1f3f4;
    box-shadow: 0px 1px 4px 0px rgba(21, 29, 41, 0.2);
    // background-color: transparent;
    width: 100%;
    height: 100%;
    // max-height: 150px;
    // font-size: 10px;
    // color: #2b2b2b;
    font-weight: 600;
  }

  .rbc-day-slot .rbc-event-label {
    display: none;
  }
  .rbc-timeslot-group {
    width: 100%;

    min-height: 151px;
  }
  .rbc-day-slot .rbc-time-slot {
    border-top: none;
  }
  .rbc-time-header-content {
    border: none !important;
  }
  .rbc-time-view .rbc-row {
    min-height: 0;
  }
  .rbc-time-view .rbc-allday-cell {
    display: none;
  }
  .rbc-day-slot .rbc-event {
    border: none;
    border-radius: 0px;
    padding: 0;
  }
  .rbc-event .rbc-selected {
    border: none;
  }
  .rbc-event:focus {
    outline: none;
  }
  .rbc-label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #418db8;
    user-select: none;
  }
  .rbc-event {
    height: unset !important;
    // top: 5% !important;
    // left: 0 !important;
  }
  .rbc-current-time-indicator {
    background-color: transparent !important;
  }
  .rbc-time-content {
    border: 1px solid #e5f7fa;
  }
  // .rbc-time-content > .rbc-day-slot{
  //   border: none;
  //   // border-width: 1px;
  //   // border-style: solid;
  //   // border-color: #d0e3ed;

  // }
  .rbc-time-content > * + * > * {
    border-left: 1px solid #e5f7fa;
    margin: 0;
    width: 100%;
  }
  .rbc-timeslot-group {
    border-bottom: 0.5px solid #e5f7fa;
  }
  .rbc-time-header {
    border: none;
  }
  .rbc-time-view {
    border: none;
  }
  .rbc-time-view .rbc-time-gutter {
    border: none !important;
  }
  .rbc-day-slot .rbc-events-container {
    margin-right: 0 !important;
  }
}
.modal_Wrapper {
  .ant-modal-header {
    height: 50px;
    padding-left: 0;
    width: 93%;
    margin: 0 auto;
    border-color: #ced0d4;
  }
  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
    width: 93%;
    margin: 0 auto;
    padding-top: 0.7rem;
    padding-bottom: 0;
  }

  .ant-modal-close-x {
    line-height: 44px;
    font-weight: 900;
  }
  .ant-modal-footer {
    border-top: none;
    padding-top: 0;
    padding-bottom: 0;
    .ant-btn-default,
    .ant-btn-primary {
      display: none;
    }
  }
}
