.input {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}

.popup {
  

  // .ant-picker-panel-container {
  :global(.ant-picker-panel-container) {
    margin-left: 0px;
    display: flex;
    // flex-direction: row-reverse;

    :global(.ant-picker-footer) {
      min-width: initial;
      border-left: 1px solid var(--Neutral-400, #A3A3A3);
    }

    :global(.ant-picker-ranges) {
      :global(.ant-tag.ant-tag-blue) {
        background: transparent;
        border-color: transparent;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--Fill-Frame-Prussian-blue, #1B2A3D);
      }
    }

    /* :global(.ant-picker-cell-range-end .ant-picker-cell-inner),
    :global(.ant-picker-cell-range-start .ant-picker-cell-inner) {
      background: #1271A6;
    } */

    :global(.ant-picker-cell-in-range::before) {
      background: #ECF3F6;
    }

    :global(.ant-picker-cell-inner) {
      border-radius: 50% !important;
    }
  }
}
